// @flow
import env from './env';
import algoliasearch, {Index} from 'algoliasearch';

const ALGOLIA_APP_ID      = env('ALGOLIA_APP_ID');
const ALGOLIA_SEARCH_KEY  = env('ALGOLIA_SEARCH_KEY');
const APP_ENV             = env('APP_ENV');
const ALGOLIA_SUFFIX      = env('ALGOLIA_SUFFIX');

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

export const DEFAULT_SUFFIX = (ALGOLIA_SUFFIX || APP_ENV);

export function createIndex(name: string): Index {
  return client.initIndex(constructIndexName(name));
}

export const constructIndexName = (name: string, suffix: string = DEFAULT_SUFFIX): string => {
  if (!suffix.length) return name;

  return name + '_' + suffix;
};

export type IAlgoliaResult = {
  hits: Array<any>,
  hitsPerPage: number,
  index?: string,
  nbHits?: number,
  nbPages?: number,
  page?: number,
  params?: string,
  processingTimeMS?: number,
  query?: string,
  exhaustiveNbHits?: boolean
};

export type IAlgoliaParams = {
  query: string,
  restrictSearchableAttributes?: Array<string>,
  facetFilters?: string
};
