import React, { Component } from 'react';

import AutocompleteInput from 'components/common/form/autocomplete-input';
import CleanBtn from 'components/common/form/clean-btn';

import { fetchPredictions, fetchProperties } from './api';

import { MAX_PROPERTY_ITEMS as MAX_ITEMS } from './constants';
import { filteredPlaces } from './utils';
import { fieldWithPrefix } from 'utils/form';
import ErrorText from 'components/common/account/ErrorText';

import { uniqueByKey } from 'utils/arrays';
import { IAlgoliaHit } from 'types';

type IErrors = {
  address: string;
};

type IProps = {
  value?: string;
  clear: boolean;
  wrapperClassName: string;
  disabled: boolean;
  empty?: boolean;
  label?: any;
  onSelect: (property: IAlgoliaHit) => any;
  onChange: (value: string) => any;
  autocompleteProps?: {
    placeholder?: string;
    inputClassName?: string;
    fieldId?: string;
    autoComplete?: string;
  };
  namePrefix?: string;
  forceRerender?: boolean;
  errors?: IErrors;
  dataCy?: string;
  algoliaEnabled?: boolean;
};

type IState = {
  value?: string;
  property?: IAlgoliaHit;
  places: IAlgoliaHit[];
  properties: IAlgoliaHit[];
};

class PropertyAutocomplete extends Component<IProps, IState> {
  static defaultProps = {
    wrapperClassName: 'col-md-9',
    disabled: false,
    labelEnable: false,
    namePrefix: '',
    forceRerender: false,
    algoliaEnabled: true
  };

  constructor(props: IProps) {
    super(props);

    const { value } = props;

    this.state = {
      value: value || '',
      places: [],
      properties: []
    };
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.empty === true) {
      this.setState({ value: '', places: [], properties: [] });
      return;
    }

    if (nextProps.forceRerender === true) {
      this.setState({ value: nextProps.value, places: [], properties: [] });
    }
  }

  onChange = (value: string) => {
    const property = undefined;

    this.setState({ property, value });
    this.props.onChange(value);

    if (!value) return;

    if (this.props.algoliaEnabled) {
      fetchProperties(value)
        .then((response) => {
          const properties = response.slice(0, MAX_ITEMS);
          const places = filteredPlaces(this.state.places, properties);
          this.setState({ properties, places });
        })
        .catch(() => this.setState({ properties: [] }));
    }

    fetchPredictions(value)
      .then((response) => {
        const places = filteredPlaces(response, this.state.properties);
        this.setState({ places });
      })
      .catch(() => this.setState({ places: [] }));
  };

  onSelect = (property: IAlgoliaHit) => {
    this.setState({ property, value: property.full_address });
    this.props.onSelect(property);
  };

  handleCleanUp = () => {
    const property = undefined;
    const value = '';

    this.setState({ property, value });
    this.props.onChange(value);
  };

  autocompleteProps = () => ({
    fieldName: fieldWithPrefix('address', this.props.namePrefix),
    placeholder: 'Address',
    ariaLabel: 'Address',
    value: this.state.value,
    onChange: this.onChange,
    onSelect: this.onSelect,
    matchBy: 'full_address',
    options: uniqueByKey(this.state.properties.concat(this.state.places), 'id'),
    disabled: this.props.disabled,
    dataCy: this.props.dataCy,
    ...this.props.autocompleteProps
  });

  render() {
    const { clear, wrapperClassName, label, namePrefix } = this.props;

    return (
      <div className={`${fieldWithPrefix('address-building', namePrefix, '-')} ${wrapperClassName}`}>
        {label}
        {/* @ts-ignore */}
        <AutocompleteInput {...this.autocompleteProps()} />
        {clear && <CleanBtn id="address-clean-btn" style="address-clean-btn" cleanUpCallback={this.handleCleanUp} />}
        {this.props.errors && Object.keys(this.props.errors).length > 0 && (
          <ErrorText text={this.props.errors.address} />
        )}
      </div>
    );
  }
}

export default PropertyAutocomplete;
