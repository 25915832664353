import React, { Component } from 'react';

import Gmap from 'components/common/gmap';

import PropertyAutocomplete from './PropertyAutocomplete';
import UnitAutocomplete from './UnitAutocomplete';
import { IProperty } from 'types/algolia/property';
import { IUnit, NullUnit } from 'types/algolia/unit';
import { NullHit } from './types';

import { IAlgoliaHit } from 'types';

export type IProps = {
  cyAddress?: string;
  cyUnit?: string;
  property: Partial<IAlgoliaHit>;
  unit: Partial<IUnit>;
  clear: boolean;
  mapEnable: boolean;
  wrapperClassName: string;
  disabled: boolean;
  empty?: boolean;
  addressLabel?: any;
  unitLabel?: any;
  unitEnabled?: boolean;
  onChange?: (property: any, unit: any) => any;
  namePrefix?: string;
  forceRerenderSeed?: number;
  errors: IErrors;
  autocompleteProps?: {
    placeholder?: string;
    inputClassName?: string;
    fieldId?: string;
    autoComplete?: string;
  };
  policy_app_property_info?: IProperty;
  policy_app_unit_info_name?: string;
  algoliaEnabled?: boolean;
};

type IErrors = {
  address: string;
};

type IState = {
  property: Partial<IAlgoliaHit>;
  unit: Partial<IUnit>;
  forceRerender: boolean;
};

// TODO: remove any from the defintion. Was having trouble deviphering props & didnt want to change functionality here
class Address extends Component<IProps & any, IState> {
  static defaultProps = {
    mapEnable: false,
    wrapperClassName: undefined,
    disabled: false,
    unit: NullUnit,
    property: NullHit,
    namePrefix: '',
    unitEnabled: true,
    algoliaEnabled: true
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      property: props.property,
      unit: props.unit,
      forceRerender: false
    };
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (this.props.empty === true) {
      this.setState({ property: NullHit, unit: NullUnit });
      return;
    }

    if (nextProps.forceRerenderSeed && nextProps.forceRerenderSeed !== this.props.forceRerenderSeed) {
      this.setState({ property: nextProps.property, unit: nextProps.unit, forceRerender: true });
    } else {
      this.setState({ forceRerender: false });
    }
  }

  setProperty = (property: IAlgoliaHit) => {
    const unit = NullUnit;
    this.setState({ property, unit });
    if (this.props.onChange) this.props.onChange(property, unit);
  };

  setUnit = (unit: IUnit) => {
    this.setState({ unit });

    if (this.props.onChange) this.props.onChange(this.state.property, unit);
  };

  onPropertySelect = (property: IAlgoliaHit) => {
    this.setProperty({ ...property, selected: true });
  };

  onUnitSelect = (value: IUnit | void) => {
    const unit = value || NullUnit;
    this.setUnit(unit);
  };

  onLineOneChange = (value: string) => {
    this.setProperty(NullHit);
  };

  onLineTwoChange = (name: string) => {
    const unit = { ...NullUnit, name };
    this.setUnit(unit);
  };

  render() {
    const {
      clear,
      disabled,
      empty,
      addressLabel,
      unitLabel,
      unitEnabled,
      policy_app_property_info,
      policy_app_unit_info_name
    } = this.props;
    const { property, unit, forceRerender } = this.state;
    const mapEnable = empty === true ? false : this.props.mapEnable;
    return (
      <div>
        {mapEnable && <Gmap place_id={property.id} />}

        <div className="row">
          <PropertyAutocomplete
            dataCy={this.props.cyAddress}
            clear={clear}
            onChange={this.onLineOneChange}
            onSelect={this.onPropertySelect}
            value={policy_app_property_info ? policy_app_property_info?.address_line_one : property.address_line_one}
            wrapperClassName={this.props.wrapperClassName}
            disabled={disabled}
            empty={empty}
            label={addressLabel}
            namePrefix={this.props.namePrefix}
            forceRerender={forceRerender}
            errors={this.props.errors}
            autocompleteProps={this.props.autocompleteProps}
            algoliaEnabled={this.props.algoliaEnabled}
          />

          {unitEnabled && (
            <UnitAutocomplete
              dataCy={this.props.cyUnit}
              value={policy_app_unit_info_name ? policy_app_unit_info_name : unit.name}
              onChange={this.onLineTwoChange}
              onSelect={this.onUnitSelect}
              property={property}
              wrapperClassName={this.props.wrapperClassName}
              disabled={disabled}
              empty={empty}
              label={unitLabel}
              namePrefix={this.props.namePrefix}
              forceRerender={forceRerender}
              autocompleteProps={this.props.autocompleteProps}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Address;
