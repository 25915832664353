import { createAction, createAsyncThunk, ActionCreatorWithPayload, PrepareAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../axios';

import { csrfToken } from 'utils/document';
import { IModalState, Invitation, IState, PolicyApplication, User } from './state';

// Simple actions
export const updateModalShown = createAction<boolean, 'updateModalShown'>('updateModalShown');
export const updateModalState = createAction<Partial<IModalState>, 'updateModalState'>('updateModalState');
export const clearRedirect = createAction<void, 'clearRedirect'>('clearRedirect');
export const startInvitation = createAction<IResult<Invitation> | undefined, 'startInvitation'>('startInvitation');
export const startNewPolicyApplication = createAction<void, 'startNewPolicyApplication'>('startNewPolicyApplication');
export const updateDisplayLoader = createAction<boolean, 'updateDisplayLoader'>('updateDisplayLoader');
export const sessionExpired = createAction<boolean, 'sessionExpired'>('sessionExpired');
export const transitionStep = createAction<string, 'transitionStep'>('transitionStep');
export const usePartnerEnrollment = createAction<void, 'usePartnerEnrollment'>('usePartnerEnrollment');

// Selectors
export const redirectSelector = (state: IState) => state.modal.redirect;
export const userSelector = (state: IState) => state.user;
export const policyApplicationSelector = (state: IState) => state.policyApplication;
export const signupStepSelector = (state: IState) => ({
  user: state.user,
  invitation: state.invitation,
  policyApplication: state.policyApplication,
  partnerRentersInsuranceRequirements: state.partnerRentersInsuranceRequirements,
  partialQuoteUnitName: state.partialQuoteUnitName
});
export const invitationsSelector = (state: IState) => state.invitations;

// Wrapper data types
export interface IResult<T> {
  result?: T;
  errors?: { [key: string]: string[] };
}

export function mapResult<A, B>(r: IResult<A>, f: (A) => B): IResult<B> {
  const result = r.result && f(r.result);
  const { errors } = r;

  return { result, errors };
}

export interface IUpdate<T> {
  value: T;
  currentStep?: string;
}

// For actions that update both a user and a policy application
// User will always be populated (either with a success or error) but the entire
// action will be short circuited if user fails, so policyApplication may not
// be present.
export interface IUCP {
  user: IResult<User>;
  policyApplication?: IResult<PolicyApplication>;
}

export function mapUpdate<A, B>(u: IUpdate<A>, f: (A) => B): IUpdate<B> {
  const { value, ...rest } = u;

  return {
    value: f(value),
    ...rest
  };
}

export function makeUpdate<T>(value: T, currentStep: string | undefined): IUpdate<T> {
  return {
    value,
    currentStep
  };
}

// Utility function
export function addCsrf<T>(t: T) {
  return Object.assign({}, t, { authenticity_token: csrfToken() });
}

// axios wrappers
export async function responseResult<T>(thunk: () => Promise<AxiosResponse<T>>): Promise<IResult<T>> {
  try {
    const response = await thunk();

    return { result: response.data };
  } catch (_e) {
    const e = _e as AxiosError<IResult<T>>;

    if (e.response) {
      return e.response.data;
    } else {
      return { errors: { request: [e.message] } };
    }
  }
}

function post<T>(route: string, body: T): Promise<IResult<T>> {
  const withCsrf = addCsrf(body);
  const thunk = () => axios.post(route, withCsrf);

  return responseResult<T>(thunk);
}

function put<T, U = T>(route: string, body: T): Promise<IResult<U>> {
  const withCsrf = addCsrf(body);
  const thunk = () => axios.put(route, withCsrf);

  return responseResult<U>(thunk);
}

function get<T>(route: string, params: object = {}): Promise<IResult<T>> {
  const withCsrf = addCsrf(params);
  const thunk = () => axios.get(route, { params: withCsrf });

  return responseResult<T>(thunk);
}

// API Actions

export const createUserAndPolicyApplicationSync: ActionCreatorWithPayload<
  {
    user?: IResult<User>;
    policyApplication?: IResult<PolicyApplication>;
  },
  'createUserAndPolicyApplicationSync'
> = createAction('createUserAndPolicyApplicationSync');

export const createPolicyApplicationSync = createAction(
  'createPolicyApplicationSync',
  (policyApplication: IResult<PolicyApplication>, currentStep?: string) => {
    return { payload: makeUpdate(policyApplication, currentStep) };
  }
);

export const updateUserSync = createAction('updateUserSync', (user: IResult<User>, currentStep?: string) => {
  return { payload: makeUpdate(user, currentStep) };
});

export const payAndSubscribeSync = createAction(
  'payAndSubscribeSync',
  (policyApplication: IResult<PolicyApplication>, currentStep?: string) => {
    return { payload: makeUpdate(policyApplication, currentStep) };
  }
);

interface IUpdatePolicyAppSignupStep {
  userId: number;
  policyAppId: number;
}

export const setPolicyAppSignupStep = createAsyncThunk(
  'setPolicyAppSignupStep',
  async (payload: IUpdate<IUpdatePolicyAppSignupStep>) => {
    const {
      value: { userId, policyAppId },
      currentStep: signup_step
    } = payload;
    const withCsrf = addCsrf({ policy_application: { signup_step } });
    const url = `/users/${userId}/policy_applications/${policyAppId}/signup_step.json`;

    try {
      const response = await axios.put(url, withCsrf);
      return {
        result: response?.data?.policy_application
      };
    } catch (_e) {
      const e = _e as AxiosError;

      return {
        errors: {
          signup_step: ['Failed to set step. Step was not provided.']
        }
      };
    }
  }
);

export interface IOpenPolicyApplication {
  userId: number;
  policyApplicationId: number;
}

export const openPolicyApplication = createAsyncThunk(
  'openPolicyApplication',
  async (payload: IOpenPolicyApplication) => {
    const url = `/users/${payload.userId}/policy_applications/${payload.policyApplicationId}.json`;
    const result = await get(url);

    return mapResult(result, (r) => r.policy_application);
  }
);

export const getCurrentPolicyApplication = createAsyncThunk('getCurrentPolicyApplication', (payload: number) => {
  const url = `/users/${payload}/policy_applications/current.json`;

  return get(url);
});

export const listInvitations = createAsyncThunk('listInvitations', async (): Promise<IResult<Invitation[]>> => {
  const url = '/invitations/sent';
  const invitations = await get(url);

  return mapResult(invitations, (r) => r);
});

export interface ICalculatePrice {
  userId: number;
  policyApplicationId: number;
}

export const calculatePrice = createAsyncThunk('calculatePrice', async (payload: ICalculatePrice) => {
  const { userId, policyApplicationId } = payload;
  const calculatePriceUrl = `/users/${userId}/policy_applications/${policyApplicationId}/calculate_price`;
  const calculatePriceResponse: IResult<{
    policy_application: PolicyApplication;
  }> = await put(calculatePriceUrl, {});

  return mapResult(calculatePriceResponse, (pa) => pa.policy_application);
});

export const updatePolicyApplicationSync = createAction<IResult<PolicyApplication>, 'updatePolicyApplicationSync'>(
  'updatePolicyApplicationSync'
);

export const updatePolicyApplicationAndAdvanceSync = createAction(
  'updatePolicyApplicationAndAdvanceSync',
  (
    policyApplication: IResult<PolicyApplication>,
    currentStep?: string
  ): ReturnType<PrepareAction<IUpdate<IResult<PolicyApplication>>>> => {
    return { payload: makeUpdate(policyApplication, currentStep) };
  }
);

export const updateUserAndPolicyApplicationSync = createAction(
  'updateUserAndPolicyApplicationSync',
  (userAndPolicyApplication: IUCP, currentStep?: string): ReturnType<PrepareAction<IUpdate<IUCP>>> => {
    return { payload: makeUpdate(userAndPolicyApplication, currentStep) };
  }
);

export interface IPaymentInfo {
  userId: number;
  policyApplicationId: number;
  paymentProps: IPaymentProps;
}

export interface IPaymentProps {
  stripeToken?: string;
  upfront: boolean;
}

export const getPartnerRentersInsuranceRequirements = createAsyncThunk(
  'getPartnerRentersInsuranceRequirements',
  async (propertyId: number | undefined) => {
    const url = `/policy_applications/${propertyId}/partner_renters_insurance_requirements`;
    const result = await get(url);
    return mapResult(result, (r) => r[0]);
  }
);

export const getPartialQuoteUnitName = createAsyncThunk(
  'getPartialQuoteUnitName',
  async (unitId: number | undefined) => {
    if (unitId === undefined) return false;
    const url = `/policy_applications/${unitId}/partial_quote_unit_name`;
    const result = await get(url);
    return result;
  }
);

export const signOut = createAsyncThunk('signOut', async (redirectTo: string): Promise<string> => {
  try {
    const csrf = addCsrf({});
    await axios.delete('/users/sign_out.json', {
      params: csrf,
      maxRedirects: 0
    });
  } catch (_e) {
    return redirectTo;
  }

  return redirectTo;
});

export async function checkInvitation(userId: number, policyApplicationId: number): Promise<IResult<Invitation>> {
  const params = addCsrf({});
  const route = `/users/${userId}/policy_applications/${policyApplicationId}/check_invitation.json`;

  try {
    const result = await axios.get(route, { params });
    if (result.status === 204) {
      return {};
    }
    const data: Invitation = result.data;

    return { result: data };
  } catch (_e) {
    return { errors: {} };
  }
}

export const checkInvitationSync = createAction<IResult<Invitation>, 'checkInvitationSync'>('checkInvitationSync');

export const sendInvitationFromAgent = createAsyncThunk('sendInvitationFromAgent', async () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('invite_id');
  if (id) {
    const url = `/invitations/${id}/send_invite_from_agent`;
    return get(url, {});
  }
});
