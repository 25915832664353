import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { ISubrogationPaymentConfirmationModal } from './state';

const initialState: ISubrogationPaymentConfirmationModal = {
  isOpen: false,
  paymentPlanDuration: 0,
  startDate: ''
};

const subrogationPaymentConfirmationModalSlice: Slice<ISubrogationPaymentConfirmationModal> = createSlice({
  name: 'subrogationPaymentConfirmationModal',
  initialState,
  reducers: {
    showSubrogationPaymentConfirmationModal: (state, _action) => ({
      ...state,
      isOpen: true
    }),
    hideSubrogationPaymentConfirmationModal: (state, _action) => ({
      ...state,
      isOpen: false
    }),
    setPaymentPlanDuration: (state, action: PayloadAction<number>) => ({
      ...state,
      paymentPlanDuration: action.payload
    }),
    setStartDate: (state, action: PayloadAction<string>) => ({
      ...state,
      startDate: action.payload
    })
  }
});

// reducers
export default subrogationPaymentConfirmationModalSlice.reducer;

// action
export const {
  showSubrogationPaymentConfirmationModal,
  hideSubrogationPaymentConfirmationModal,
  setPaymentPlanDuration,
  setStartDate
} = subrogationPaymentConfirmationModalSlice.actions;
