import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';
import { ICurrentStep, nextStep, stepUrl } from '../../steps';
import {
  calculatePrice,
  checkInvitationSync,
  clearRedirect,
  getCurrentPolicyApplication,
  IResult,
  IUCP,
  IUpdate,
  openPolicyApplication,
  setPolicyAppSignupStep,
  updateModalShown,
  updateModalState,
  startInvitation,
  signOut,
  createUserAndPolicyApplicationSync,
  createPolicyApplicationSync,
  updatePolicyApplicationSync,
  updateDisplayLoader,
  sendInvitationFromAgent,
  startNewPolicyApplication,
  transitionStep,
  sessionExpired,
  getPartnerRentersInsuranceRequirements,
  listInvitations,
  updateUserSync,
  updateUserAndPolicyApplicationSync,
  updatePolicyApplicationAndAdvanceSync,
  payAndSubscribeSync,
  getPartialQuoteUnitName,
  usePartnerEnrollment
} from '../actions';
import {
  Invitation,
  IModalState,
  IState,
  PolicyApplication,
  User,
  IPartnerRentersInsuranceRequirements,
  IUnitName
} from '../state';

const initialInvitation: Invitation | undefined = (window as any).App.invitation || undefined;
const initialInvitationState: IResult<Invitation> = {
  result: initialInvitation
};

const initialPartnerRentersInsuranceRequirements: IPartnerRentersInsuranceRequirements = {};
const initialPartnerRentersInsuranceRequirementsState: IResult<IPartnerRentersInsuranceRequirements> = {
  result: initialPartnerRentersInsuranceRequirements
};

export const partnerRentersInsuranceRequirementsSlice: Slice<IResult<IPartnerRentersInsuranceRequirements>> =
  createSlice({
    name: 'partnerRentersInsuranceRequirements',
    initialState: initialPartnerRentersInsuranceRequirementsState,
    reducers: {},
    extraReducers: {
      [getPartnerRentersInsuranceRequirements.fulfilled.type]: (_state, action) => {
        const result = action.payload;
        return result;
      }
    }
  });

const initialPartialQuoteUnitName: IUnitName = {};
const initialPartialQuoteUnitNameState: IResult<IUnitName> = {
  result: initialPartialQuoteUnitName
};

export const initialPartialQuoteUnitNameSlice: Slice<IResult<IUnitName>> = createSlice({
  name: 'initialPartialQuoteUnitName',
  initialState: initialPartialQuoteUnitNameState,
  reducers: {},
  extraReducers: {
    [getPartialQuoteUnitName.fulfilled.type]: (_state, action) => {
      const result = action.payload;
      return result;
    }
  }
});

export const invitationSlice: Slice<IResult<Invitation>> = createSlice({
  name: 'invitation',
  initialState: initialInvitationState,
  reducers: {},
  extraReducers: {
    [checkInvitationSync.type]: (_state: IResult<Invitation>, action: PayloadAction<IResult<Invitation>>) => {
      const result = action.payload;
      return result;
    },
    [startInvitation.type]: (state: IResult<Invitation>, action: PayloadAction<IResult<Invitation> | undefined>) => {
      const invitation = action.payload?.result;
      if (invitation !== undefined) {
        return { result: invitation };
      }
      return { ...state };
    },
    [startNewPolicyApplication.type]: () => ({})
  }
});

const initialModalState = (): IModalState => {
  const { user, policy_application: policyApplication, invitation } = (window as any).App;
  const invite = window.location.search.match(/^\?invite_id\=(\d*)/);
  if (invite) {
    return { visible: true };
  }
  const onProfile = window.location.pathname.match(/^\/users\/edit.*/) !== null;
  const onPolicies = window.location.pathname === '/policies';
  const onInvitationPage = window.location.pathname.match(/^\/enroll\/invitation\/.+/) !== null;
  const applicationInProgress = user && policyApplication && !policyApplication.subscribed && !onProfile && !onPolicies;
  const startingInvitation = invitation && onInvitationPage;

  if (applicationInProgress || startingInvitation) {
    if (user && policyApplication) {
      const { signup_step: step } = policyApplication;
      const currentStepUrl = stepUrl(step, { policyApplication });

      if (!window.location.pathname.startsWith(currentStepUrl) && currentStepUrl !== '/enroll/unknown') {
        return {
          visible: true,
          redirect: currentStepUrl
        };
      }
      // if a user is not subscribed, logs in, & has an in-progress application with no step set
      if (currentStepUrl === '/enroll/unknown') {
        return {
          visible: true,
          redirect: invitation ? '/enroll/invitation/personal_information' : '/enroll/get_started'
        };
      }
    }

    return { visible: true, displayLoader: false };
  }

  return { visible: false, displayLoader: false };
};

export const modalSlice: Slice<IModalState> = createSlice({
  name: 'modal',
  initialState: initialModalState(),
  reducers: {},
  extraReducers: {
    [updateModalShown.type]: (state, action) => Object.assign(state, { visible: action.payload }),
    [updateModalState.type]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [clearRedirect.type]: (state, _action) => {
      delete state.redirect;
      return state;
    },
    [updateDisplayLoader.type]: (state, action) => ({
      ...state,
      displayLoader: action.payload
    }),
    [sessionExpired.type]: (state, action) => {
      return Object.assign(state, {
        visible: action.payload,
        redirect: '/enroll/expired_token'
      });
    },
    [usePartnerEnrollment.type]: (state) => {
      return Object.assign(state, {
        redirect: '/enroll/get_standard_security_deposit'
      });
    },
    [checkInvitationSync.type]: (state: IModalState, action: PayloadAction<IResult<Invitation>>) => {
      const { payload } = action;
      if (payload.result !== undefined) {
        return {
          ...state,
          redirect: stepUrl('lease_dates', {
            forceInvitation: true,
            promptChange: true
          })
        };
      }
      return state;
    },
    [createUserAndPolicyApplicationSync.type]: (
      state: IModalState,
      action: PayloadAction<{
        user?: IResult<User>;
        policyApplication?: IResult<PolicyApplication>;
      }>
    ) => {
      const { user, policyApplication } = action.payload;
      const visible = user?.errors === undefined && policyApplication?.errors === undefined;
      return Object.assign(state, {
        visible
      });
    },
    [createPolicyApplicationSync.type]: (
      state: IModalState,
      action: PayloadAction<IUpdate<IResult<PolicyApplication>>>
    ) => {
      const visible = action.payload.value.errors === undefined || state.visible;
      return Object.assign(state, {
        visible
      });
    },
    [startInvitation.type]: (state: IModalState, action: PayloadAction<IResult<PolicyApplication>>) => {
      const errors = action.payload?.errors;
      if (errors !== undefined) {
        return state;
      }

      let pathToRedirectWhenActiveProperty = '/enroll/invitation/personal_information';

      if (initialInvitation?.enrollment_link != null) {
        const parsedUrl = new URL(initialInvitation?.enrollment_link);
        pathToRedirectWhenActiveProperty = parsedUrl.pathname
      }
      const route =
        initialInvitation?.active_property === false
          ? '/enroll/expired_invitation'
          : pathToRedirectWhenActiveProperty;
      return Object.assign(state, {
        visible: true,
        redirect: route
      });
    },
    [startNewPolicyApplication.type]: () => {
      return { visible: true };
    },
    [sendInvitationFromAgent.fulfilled.type]: (
      state: IModalState,
      action: PayloadAction<IResult<PolicyApplication>>
    ) => {
      return Object.assign(state, {
        visible: true,
        redirect: '/enroll/check_your_inbox'
      });
    },
    [signOut.fulfilled.type]: (state: IModalState, action: PayloadAction<string>) => {
      return {
        ...state,
        visible: false,
        redirect: action.payload
      };
    }
  }
});

const initialUser: User | undefined = (window as any).App.user || undefined;
const initialUserState: IResult<User> = {
  result: initialUser
};

export const userSlice: Slice<IResult<User>> = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {},
  extraReducers: {
    [createUserAndPolicyApplicationSync.type]: (
      state: IResult<User>,
      action: PayloadAction<{
        user?: IResult<User>;
        policyApplication?: IResult<PolicyApplication>;
      }>
    ) => {
      const { user } = action.payload;
      if (user?.errors !== undefined) {
        return { ...state, errors: user.errors };
      } else {
        return user;
      }
    },
    [updateUserSync.type]: (state, a: PayloadAction<IUpdate<IResult<User>>>) => {
      const { value: result } = a.payload;
      const { errors } = result;
      if (errors !== undefined) {
        return { ...state, errors };
      } else {
        return { ...result };
      }
    },
    [updateUserAndPolicyApplicationSync.type]: (state, action: PayloadAction<IUpdate<IUCP>>) => {
      const {
        value: { user }
      } = action.payload;
      if (user.errors !== undefined) {
        return { ...state, errors: user.errors };
      } else {
        return user;
      }
    },
    [signOut.fulfilled.type]: (state, action: PayloadAction<number | undefined>) => {
      return {};
    }
  }
});

const initialPolicyApplication: PolicyApplication | undefined = (window as any).App.policy_application || undefined;
const initialPolicyApplicationState: IResult<PolicyApplication> = {
  result: initialPolicyApplication
};

export const policyApplicationSlice: Slice<IResult<PolicyApplication>> = createSlice({
  name: 'policyApplication',
  initialState: initialPolicyApplicationState,
  reducers: {},
  extraReducers: {
    [createUserAndPolicyApplicationSync.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<{
        user?: IResult<User>;
        policyApplication?: IResult<PolicyApplication>;
      }>
    ) => {
      const { policyApplication: policyApp } = action.payload;
      if (policyApp?.errors !== undefined) {
        return { ...state, errors: policyApp.errors };
      } else {
        return policyApp;
      }
    },
    [createPolicyApplicationSync.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<IUpdate<IResult<PolicyApplication>>>
    ) => {
      const policyApp = action.payload.value;
      if (policyApp?.errors !== undefined) {
        return { ...state, errors: policyApp.errors };
      } else {
        return policyApp;
      }
    },
    [openPolicyApplication.fulfilled.type]: (state, action) => {
      return action.payload;
    },
    [getCurrentPolicyApplication.fulfilled.type]: (state, action) => {
      return action.payload;
    },
    [payAndSubscribeSync.type]: (state, action: PayloadAction<IUpdate<IUCP>>) => {
      const {
        value: { policyApplication }
      } = action.payload;
      if (policyApplication === undefined) {
        return state;
      }
      if (policyApplication.errors !== undefined) {
        return { ...state, errors: policyApplication.errors };
      } else {
        return policyApplication;
      }
    },
    [calculatePrice.fulfilled.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<IResult<PolicyApplication>>
    ) => {
      const result = action.payload;
      const { errors } = result;
      if (errors !== undefined) {
        return { ...state, errors };
      } else {
        return result;
      }
    },
    [updatePolicyApplicationSync.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<IResult<PolicyApplication>>
    ) => {
      if (action.payload.errors) {
        return {
          ...state,
          errors: action.payload.errors
        };
      }
      return action.payload;
    },
    [updatePolicyApplicationAndAdvanceSync.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<IUpdate<IResult<PolicyApplication>>>
    ) => {
      if (action.payload.value.errors) {
        return {
          ...state,
          errors: action.payload.value.errors
        };
      }
      return action.payload.value;
    },
    [updateUserAndPolicyApplicationSync.type]: (state, action: PayloadAction<IUpdate<IUCP>>) => {
      const {
        value: { policyApplication }
      } = action.payload;
      if (policyApplication === undefined) {
        return state;
      }
      if (policyApplication.errors !== undefined) {
        return { ...state, errors: policyApplication.errors };
      } else {
        return policyApplication;
      }
    },
    [signOut.fulfilled.type]: (state, action: PayloadAction<number | undefined>) => {
      return {};
    },
    [startInvitation.type]: (
      state: IResult<PolicyApplication>,
      action: PayloadAction<IResult<Invitation> | undefined>
    ) => {
      const errors = action.payload?.errors;
      const invitation = action.payload?.result;
      if (errors !== undefined) {
        return { ...state, errors };
      }
      if (invitation?.id !== undefined && invitation.id === state.result?.partial_quote_id) {
        return { ...state };
      } else {
        return {};
      }
    },
    [startNewPolicyApplication.type]: () => ({}),
    [checkInvitationSync.type]: (state: IResult<PolicyApplication>, action: PayloadAction<IResult<Invitation>>) => {
      const { payload } = action;
      const policyApplicationId = state.result?.id;

      if (payload.result !== undefined && policyApplicationId) {
        localStorage.setItem(`previousStep-${policyApplicationId}`, 'quote_display');
      }

      return state;
    },
    [setPolicyAppSignupStep.fulfilled.type]: (_state, action: PayloadAction<IResult<PolicyApplication>>) => {
      return {
        ...action.payload
      };
    }
  }
});

const initialInvitationsState: Invitation[] = [];

export const invitationsSlice: Slice<Invitation[]> = createSlice({
  name: 'invitations',
  initialState: initialInvitationsState,
  reducers: {},
  extraReducers: {
    [listInvitations.fulfilled.type]: (_state, action: PayloadAction<IResult<Invitation[]>>) => {
      if (action.payload.result !== undefined) {
        return action.payload.result;
      }
      return [];
    }
  }
});

// metaReducer is responsible for transitioning steps
export const metaReducer = (state: IState, action: PayloadAction) => {
  const extractStep: object = {
    [updateUserSync.type]: (payload: IUpdate<IResult<User>>) => {
      return {
        currentStep: payload.currentStep,
        errors: !isUndefined(payload.value.errors)
      };
    },
    [payAndSubscribeSync.type]: (payload: IUpdate<IResult<PolicyApplication>>) => {
      return {
        currentStep: payload.currentStep,
        errors: !isUndefined(payload.value.errors)
      };
    },
    [createUserAndPolicyApplicationSync.type]: (payload) => {
      const errors = payload.user?.errors !== undefined || payload.policyApplication?.errors !== undefined;
      return { errors };
    },
    [createPolicyApplicationSync.type]: (payload) => {
      return { currentStep: payload.currentStep, errors: !isUndefined(payload.value.errors) };
    },
    [updateUserAndPolicyApplicationSync.type]: (payload: IUpdate<IUCP>) => {
      return {
        currentStep: payload.currentStep,
        errors: !(isUndefined(payload.value.policyApplication?.errors) && isUndefined(payload.value.user?.errors))
      };
    },
    [updatePolicyApplicationAndAdvanceSync.type]: (payload: IUpdate<IResult<PolicyApplication>>) => {
      return {
        currentStep: payload.currentStep,
        errors: !isUndefined(payload.value.errors)
      };
    },
    [startNewPolicyApplication.type]: () => ({ currentStep: 'dashboard' }),
    [transitionStep.type]: (currentStep: string) => ({ currentStep })
  };
  const { user, policyApplication, invitation, partnerRentersInsuranceRequirements } = state;

  if (user.errors?.redirect || policyApplication.errors?.redirect) {
    const url = user.errors?.redirect[0] || policyApplication.errors?.redirect[0];

    if (url) {
      window.location.href = url;
      return state;
    }
  } else if (user.errors?.step_redirect || policyApplication.errors?.step_redirect) {
    const step = user.errors?.step_redirect[0] || policyApplication.errors?.step_redirect[0];

    if (step) {
      // We must blank out the errors to prevent redirect loops.
      return {
        ...state,
        user: {
          result: user.result
        },
        policyApplication: {
          result: policyApplication.result
        },
        modal: {
          redirect: stepUrl(step, {
            policyApplication: policyApplication.result
          }),
          visible: true
        }
      };
    }
  }

  const extractFn: ((payload: any) => ICurrentStep | undefined) | undefined = extractStep[action.type];

  if (extractFn) {
    const currentStep = extractFn(action.payload);
    const next = nextStep(policyApplication, user, invitation, partnerRentersInsuranceRequirements, currentStep);
    const policyApplicationId = policyApplication.result?.id;
    const lsPreviousStepKey = `previousStep-${policyApplicationId}`;
    const previousStep = localStorage.getItem(lsPreviousStepKey);
    if (next.success && next.step) {
      state.modal = {
        ...state.modal,
        visible: true,
        displayLoader: false,
        redirect: stepUrl(next.step, { policyApplication: policyApplication.result })
      };
    }

    if (next.step === 'user_profile') {
      const redirectPath =
        policyApplication?.result?.eligible_for_lemonade_offer ?
          '/enroll/offers/lemonade' :
          '/users/edit';
      window.location.href = redirectPath;
    }

    if (policyApplicationId && previousStep) {
      localStorage.removeItem(lsPreviousStepKey);

      state.modal = {
        ...state.modal,
        visible: true,
        redirect: stepUrl(previousStep, {
          policyApplication: policyApplication.result
        })
      };
    }
  } else if (action.type === openPolicyApplication.fulfilled.type) {
    const next = nextStep(policyApplication, user, invitation, partnerRentersInsuranceRequirements, {
      currentStep: 'dashboard'
    });
    if (next.success && next.step) {
      state.modal = {
        ...state.modal,
        visible: true,
        redirect: stepUrl(next.step, {
          policyApplication: policyApplication.result
        })
      };
    }
  } else if (action.type === signOut.fulfilled.type && state.modal.redirect) {
    // Signing out should trigger a full page reload.
    window.location.assign(state.modal.redirect);
    state.modal = {
      ...state.modal,
      visible: true
    };
  }

  return state;
};
