import React, { Component } from 'react';

type IProps = {
  style: string;
  id: string;
  cleanUpCallback: () => any;
};

class CleanBtn extends Component<IProps> {
  static defaultProps = {
    style: '',
    id: ''
  };

  constructor(props: IProps) {
    super(props);
  }

  handleCleanUp = () => {
    this.props.cleanUpCallback();
  };

  render() {
    const { style, id } = this.props;

    return (
      <div id={id} className={'clean-btn ' + style} onClick={this.handleCleanUp}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
    );
  }
}

export default CleanBtn;
