export type IUnit = {
  id?: number;
  name?: string;
  building_name?: string;
  full_address?: string;
  first_name?: string;
  last_name?: string;
  coverage_amount_cents?: number;
  address_line_one?: string;
  address_line_two?: string;
  address_city?: string;
  address_zip?: string;
  address_state?: string;
  property_id?: number;
  reference_prefixes?: string[];
};

export const NullUnit = {
  name: '',
  coverage_amount_cents: 0,
  reference_prefixes: []
};
