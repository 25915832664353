import { combineReducers, configureStore, PayloadAction } from '@reduxjs/toolkit';
import subrogationPaymentConfirmationModalSlice from 'components/v2/subro_payment_plans/redux/reducers';
import {
  invitationSlice,
  metaReducer,
  modalSlice,
  policyApplicationSlice,
  userSlice,
  invitationsSlice,
  partnerRentersInsuranceRequirementsSlice,
  initialPartialQuoteUnitNameSlice
} from './reducers';

export default configureStore({
  reducer: (state, action: PayloadAction) => {
    const slices = {
      modal: modalSlice.reducer,
      subrogationPaymentConfirmationModal: subrogationPaymentConfirmationModalSlice,
      user: userSlice.reducer,
      policyApplication: policyApplicationSlice.reducer,
      invitation: invitationSlice.reducer,
      invitations: invitationsSlice.reducer,
      partnerRentersInsuranceRequirements: partnerRentersInsuranceRequirementsSlice.reducer,
      partialQuoteUnitName: initialPartialQuoteUnitNameSlice.reducer
    };
    const combined = combineReducers(slices);
    return metaReducer(combined(state, action), action);
  }
});
