type IState = {
  highlightedIndex?: number;
};

type IProps = {
  value: string;
  getItemValue: any;
};

type IMaybeHighlightedIndex = IState;

export function maybeAutoCompleteText(state: IState, props: IProps): IMaybeHighlightedIndex {
  const { highlightedIndex }: IMaybeHighlightedIndex = state;
  const { value, getItemValue }: IProps = props;
  const index: number | void = highlightedIndex === null ? 0 : highlightedIndex;

  // I have no idea how this ever worked. Ignoring since fixing this isn't the goal of this work
  // @ts-ignore
  const filteredItems = this.getFilteredItems(props);
  // @ts-ignore
  const matchedItem = filteredItems[index];

  if (value && matchedItem) {
    const itemValue = getItemValue(matchedItem);

    const itemValueDoesMatch: boolean = itemValue.toLowerCase().indexOf(value.toLowerCase()) === 0;

    if (itemValueDoesMatch) {
      // @ts-ignore
      return { highlightedIndex: index };
    }
    // fallback to always to higlight top item if it exists
    if (filteredItems.length) return { highlightedIndex: 0 };
  }
  // @ts-ignore
  return { highlightedIndex: null };
}
