// @flow
export function uniqueFilter(item: mixed, i: number, ar: Array<mixed>): boolean {
  return ar.indexOf(item) === i;
}

export function unique(a: Array<any>): Array<any> {
  return a.filter(uniqueFilter);
}

export function uniqueByKey(a: Array<any>, k: string): Array<any> {
  return a.filter((obj, pos, arr) => arr.map(mapObj => mapObj[k]).indexOf(obj[k]) === pos);
}
