import { IResult } from './redux/actions';
import { Invitation, IPartnerRentersInsuranceRequirements, PolicyApplication, User } from './redux/state';
import env from 'utils/env';

const INVITATION_STEPS = new Set(['personal_information', 'lease_dates']);

interface IStepUrlOptions {
  policyApplication?: PolicyApplication;
  forceInvitation?: boolean;
  promptChange?: boolean;
}

export function stepUrl(step: string, options?: IStepUrlOptions): string {
  const invitation = options?.policyApplication?.partial_quote_id !== undefined || options?.forceInvitation;
  if (invitation && INVITATION_STEPS.has(step)) {
    if (options?.promptChange) {
      return `/enroll/invitation/${step}?prompt=true`;
    } else {
      return `/enroll/invitation/${step}`;
    }
  }
  return `/enroll/${step}`;
}

export interface INextStep {
  step?: string;
  success?: boolean;
}

interface ITransitionFnArgs {
  policyApp: IResult<PolicyApplication>;
  user: IResult<User>;
  partnerRentersInsuranceRequirements?: IResult<IPartnerRentersInsuranceRequirements>;
}

type TransitionFn = (transitionFnObj: ITransitionFnArgs) => INextStep;
type TransitionCombinator = (cb: TransitionFn) => TransitionFn;

const invitationOnly: TransitionCombinator = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { policyApp } = args;

  if (policyApp.result?.partial_quote_id !== undefined) {
    return cb(args);
  } else {
    return { success: false };
  }
};

const policyAppRequiredFields =
  (...fields) =>
  (cb: TransitionFn) =>
  (args: ITransitionFnArgs) => {
    const { policyApp } = args;

    const success = fields
      .map((x) => policyApp.result && policyApp.result[x] !== undefined && JSON.stringify(policyApp.result[x]) !== '{}')
      .reduce((m, x) => m && x);
    if (success) {
      return cb(args);
    } else {
      return { success: false };
    }
  };

const userRequiredFields =
  (...fields) =>
  (cb: TransitionFn) =>
  (args: ITransitionFnArgs) => {
    const { user } = args;
    const success = fields.map((x) => user.result && user.result[x] !== undefined).reduce((m, x) => m && x);

    if (success) {
      return cb(args);
    } else {
      return { success: false };
    }
  };

const noUserError = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { user } = args;

  if (user.errors !== undefined) {
    return { success: false };
  }
  return cb(args);
};

const noPolicyAppError = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { policyApp } = args;

  if (policyApp.errors !== undefined) {
    return { success: false };
  }
  return cb(args);
};

const noError = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { policyApp, user } = args;

  if (policyApp.errors !== undefined || user.errors !== undefined) {
    return { success: false };
  }
  return cb(args);
};

const policyAppValue = (field, value) => (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { policyApp } = args;

  if (policyApp.result && policyApp.result[field] === value) {
    return cb(args);
  }
  return { success: false };
};

const userValue = (field, value) => (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const { user } = args;
  if (user.result && user.result[field] === value) {
    return cb(args);
  }
  return { success: false };
};

const qualifiesForCoverGenuisRentersInsurance = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const rentersInsuranceStepEnabled = (window as any).App?.featureFlags?.signUp?.rentersInsuranceAnnualAtSignUp;
  const addressState = args.policyApp.result?.address_state;
  const partnerRentersInsuranceRequirements =
    args.partnerRentersInsuranceRequirements?.result?.active_renters_insurances_requirements;
  const ownerDidNotIndicateRentersInsuranceRequirements = partnerRentersInsuranceRequirements === undefined;
  const ownerDidNotIndicateRequireRI =
    partnerRentersInsuranceRequirements?.requires_renters_insurance === (undefined || null);
  const ownerHasNoEMA = partnerRentersInsuranceRequirements?.has_exclusive_agreement !== true;
  const partnerEligibleForGeniusRentersInsurance = args.policyApp.result?.partner_eligible_for_renters_insurance;
  const eligibleForCoverGeniusRentersInsurance =
    !args.policyApp.result?.eligible_for_lemonade_offer &&
    rentersInsuranceStepEnabled &&
    env('RENTERS_INSURANCE_STATES').includes(addressState) &&
    partnerEligibleForGeniusRentersInsurance &&
    ownerHasNoEMA &&
    (ownerDidNotIndicateRentersInsuranceRequirements || ownerDidNotIndicateRequireRI);

  if (eligibleForCoverGeniusRentersInsurance) {
    return cb(args);
  }

  return { success: false };
};

const householdRoutesEnabled = (cb: TransitionFn) => (args: ITransitionFnArgs) => {
  const householdActive = (window as any)?.App?.featureFlags?.householdQuestion === true;

  if (householdActive) {
    return cb(args);
  }
  return { success: false };
};

const andC =
  (...combinators: TransitionCombinator[]) =>
  (cb: TransitionFn) => {
    return combinators.reverse().reduce((m, x) => {
      return x(m);
    }, cb);
  };

const andConst =
  (...combinators: TransitionCombinator[]) =>
  (step: string) =>
    andC(...combinators)(constRoute(step));

const or =
  (...transitions: TransitionFn[]) =>
  (args: ITransitionFnArgs) => {
    return transitions.reduce(
      (res: INextStep, transition) => {
        if (res.success) {
          return res;
        }
        return transition(args);
      },
      { success: false }
    );
  };

const orC =
  (...combinators: TransitionCombinator[]) =>
  (cb: TransitionFn) =>
  (args: ITransitionFnArgs) => {
    return combinators.reduce(
      (res: INextStep, combinator) => {
        if (res.success) {
          return res;
        }
        return combinator(cb)(args);
      },
      { success: false }
    );
  };

const constRoute: (step: string) => TransitionFn = (step: string) => () => ({ success: true, step });

const personalInformation = andConst(
  noUserError,
  noPolicyAppError,
  userRequiredFields('first_name', 'last_name', 'birthdate', 'phone')
)('lease_dates');

const getStarted = noPolicyAppError(
  or(policyAppRequiredFields('property_id')(constRoute('personal_information')), constRoute('not_there_yet'))
);

const notThereYet = andConst(noPolicyAppError, policyAppRequiredFields('property_id'))('personal_information');

const leaseDates = noPolicyAppError(
  or(
    invitationOnly(
      or(
        policyAppRequiredFields('price_info')(constRoute('products_offered')),
        or(
          qualifiesForCoverGenuisRentersInsurance(constRoute('renters_insurance_required')),
          constRoute('most_recent_address')
        )
      )
    ),
    policyAppRequiredFields('lease_start_date', 'lease_end_date')(constRoute('rent'))
  )
);

const rent = andC(
  noError,
  policyAppRequiredFields('monthly_rent')
)(
  or(
    qualifiesForCoverGenuisRentersInsurance(constRoute('renters_insurance_required')),
    constRoute('most_recent_address')
  )
);

const rentersInsuranceRequired = noPolicyAppError(
  policyAppRequiredFields('renters_insurance_required')(constRoute('most_recent_address'))
);

const mostRecentAddress = andConst(
  noPolicyAppError,
  orC(policyAppRequiredFields('recent_address_line_one'), policyAppRequiredFields('first_time_renter'))
)('employment_status');

const employmentStatus = or(householdRoutesEnabled(constRoute('household')), constRoute('income'));

const household = or(householdRoutesEnabled(constRoute('pets')), constRoute('income'));

const pets = andConst(noError)('income');

const lastYearIncome = andC(noError, policyAppRequiredFields('yearly_income'))(constRoute('assets'));

const assets = andConst(noError)('education_level');

const educationLevel = noError(or(policyAppRequiredFields('education_level_slug')(constRoute('citizenship'))));

const social = or(
  andC(
    noError,
    policyAppValue('has_ssn', true),
    userRequiredFields('social_security_number')
  )(constRoute('products_offered')),
  andC(noError, userValue('has_ssn', false))(constRoute('products_offered'))
);

const productsOffered = or(
  andConst(noError, policyAppValue('cash_deposit_status', 'selected'))('cash_deposit'),
  andConst(
    noError,
    policyAppRequiredFields('price_info'),
    policyAppValue('cash_deposit_status', undefined)
  )('quote_display')
);

const cashDeposit = or(
  andConst(
    noError,
    policyAppRequiredFields('deposify_deposit_uid'),
    policyAppValue('cash_deposit_status', 'paid'),
    userValue('skip_password', true)
  )('user_profile'),
  andConst(
    noError,
    policyAppRequiredFields('deposify_deposit_uid'),
    policyAppValue('cash_deposit_status', 'paid')
  )('password')
);

const citizenship = andConst(noPolicyAppError, noUserError)('social');

const quoteDisplay = or(
  andConst(noError, policyAppRequiredFields('price_info'), userValue('skip_password', true))('user_profile'),
  andConst(noError, policyAppRequiredFields('price_info'))('password')
);

const dashboard = noError(constRoute('get_started'));
const password = noError(constRoute('user_profile'));
const getStandardSecurityDeposit = constRoute('get_standard_security_deposit');

// current step : "back" step
export const STEPS_BACK = {
  not_there_yet: constRoute('address'),
  personal_information: constRoute('address'),
  lease_dates: constRoute('personal_information'),
  rent: constRoute('lease_dates'),
  renters_insurance_required: or(invitationOnly(constRoute('lease_dates')), constRoute('rent')),
  most_recent_address: or(
    qualifiesForCoverGenuisRentersInsurance(constRoute('renters_insurance_required')),
    or(invitationOnly(constRoute('lease_dates')), constRoute('rent'))
  ),
  employment_status: constRoute('most_recent_address'),
  household: constRoute('employment_status'),
  pets: or(householdRoutesEnabled(constRoute('household')), constRoute('employment_status')),
  income: or(householdRoutesEnabled(constRoute('pets')), constRoute('employment_status')),
  assets: constRoute('income'),
  education_level: constRoute('assets'),
  citizenship: or(policyAppRequiredFields('education_level_slug')(constRoute('education_level'))),
  social: constRoute('citizenship'),
  products_offered: or(
    andConst(policyAppValue('has_ssn', true), userRequiredFields('social_security_number'))('social'),
    andConst(policyAppValue('has_ssn', false))('social')
  ),
  cash_deposit: constRoute('products_offered'),
  quote_display: constRoute('products_offered'),
  password: constRoute('cash_deposit'),
  get_standard_security_deposit: constRoute('products_offered')
};

export const STEPS: { [key: string]: TransitionFn } = {
  dashboard,
  get_started: getStarted,
  not_there_yet: notThereYet,
  personal_information: personalInformation,
  lease_dates: leaseDates,
  rent,
  renters_insurance_required: rentersInsuranceRequired,
  most_recent_address: mostRecentAddress,
  employment_status: employmentStatus,
  household,
  pets,
  assets,
  education_level: educationLevel,
  income: lastYearIncome,
  citizenship,
  social,
  products_offered: productsOffered,
  cash_deposit: cashDeposit,
  quote_display: quoteDisplay,
  password,
  get_standard_security_deposit: getStandardSecurityDeposit
};

export interface ICurrentStep {
  currentStep: string;
  errors?: boolean;
}

// The boolean represents whether or not the lookup was successful. The string
// will be the next step. If the lookup was successful, but you were on the last
// step, the next step will be undefined.
export function nextStep(
  policyApp: IResult<PolicyApplication>,
  user: IResult<User>,
  invitation: IResult<Invitation>,
  partnerRentersInsuranceRequirements: IResult<IPartnerRentersInsuranceRequirements>,
  current?: ICurrentStep
): INextStep {
  if (policyApp.result === undefined && user.result === undefined) {
    return { success: false }; // for landing page errors
  }

  if (current?.currentStep === 'dashboard') {
    return { success: true, step: 'get_started' };
  }

  if (current?.currentStep) {
    const nextStepTransition = STEPS[current.currentStep]({
      policyApp,
      user,
      partnerRentersInsuranceRequirements
    });
    return nextStepTransition;
  } else if (policyApp.result?.partial_quote_id && current?.currentStep === undefined) {
    return { success: true, step: 'lease_dates' };
  } else if (policyApp.result?.property_id && current?.currentStep === undefined) {
    return { success: true, step: 'personal_information' };
  } else {
    return { success: true, step: 'not_there_yet' };
  }
}

export function previousStep(
  policyApp: IResult<PolicyApplication>,
  user: IResult<User>,
  current: string
): string | undefined {
  const prevStepTransition = STEPS_BACK[current];
  if (prevStepTransition !== undefined) {
    const prevStep: INextStep = prevStepTransition({ policyApp, user });
    return prevStep.step;
  }
}
