import { request } from './request';
import env from './env';

const OK = 'OK';
const ZERO_RESULTS = 'ZERO_RESULTS';

const GOOGLE_JSON_API_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
const googleMapsApiSrc = () => `https://maps.googleapis.com/maps/api/js?key=${env('GOOGLE_API_KEY')}&libraries=places`;
export const googleMapsApiScriptElId = 'google-maps-api-script';
export const googleMapsApiErrorContainerElId = "google-maps-api-error-message-container";
const googleMapsApiMaxRetryAttempts = 3;

let googleMapsApiRetryCount = 0, retryAttemptInProgress = false, maxAttemptsExceeded = false;

export function retryLoadGoogleMapsAPI() {
  return new Promise((resolve, reject) => {
    if (retryAttemptInProgress) return reject('retryLoadGoogleMapsAPI :: Retry attempt already in progress.');
    if (maxAttemptsExceeded) return reject('retryLoadGoogleMapsAPI :: Max attempts exceeded.');

    if (window.google) return resolve();

    const loadScript = () => {
      retryAttemptInProgress = true;
      const existingRetryScriptEl = document.getElementById(googleMapsApiScriptElId)
      if (existingRetryScriptEl) existingRetryScriptEl.remove();

      const script = document.createElement('script');
      script.id = googleMapsApiScriptElId;
      script.src = googleMapsApiSrc();

      script.onload = () => {
        retryAttemptInProgress = false;
        return resolve();
      }

      script.onerror = (error) => {
        retryAttemptInProgress = false;
        googleMapsApiRetryCount++;

        if (googleMapsApiRetryCount < googleMapsApiMaxRetryAttempts) return setTimeout(loadScript, 1000);
        maxAttemptsExceeded = true;

        const errorContainerEl = document.getElementById(googleMapsApiErrorContainerElId)

        if (errorContainerEl) errorContainerEl.style.display = 'block';
        console.error('Failed to load Google Maps API. Max Attempts Exceeded', { error, googleMapsApiRetryCount, googleMapsApiMaxRetryAttempts})
        return reject('Failed to load Google Maps API. Max Attempts Exceeded', { error, googleMapsApiRetryCount, googleMapsApiMaxRetryAttempts});
      };

      document.body.appendChild(script);
    };

    loadScript();
  });
}

export function fetchGooglePredictions(query, sessionToken) {
  return new Promise((resolve, reject) => {
    if (!window.google) {
      return retryLoadGoogleMapsAPI()
        .then(() => fetchGooglePredictions(query, sessionToken))
        .catch(() => reject('Google Maps API not loaded.'));
    }

    const service = new window.google.maps.places.AutocompleteService();

    const callback = (predictions, status) => {
      if (status == OK || status == ZERO_RESULTS) {
        resolve(predictions || []);
      } else {
        reject(status);
      }
    }

    const payload = {
      input: query,
      componentRestrictions: { country: 'US' },
      types: ['address'],
      sessionToken: sessionToken
    };

    service.getPlacePredictions(payload, callback);
  });
}

export function fetchGoogleAddress(address) {
  const payload = {
    key: GOOGLE_API_KEY,
    address,
  };

  return new Promise((resolve, reject) => {
    request(GOOGLE_JSON_API_URL, payload).then(response => {
      const { status, results } = response;

      if (status == OK || status == ZERO_RESULTS) {
        resolve(results || []);
      } else {
        reject(status);
      }
    });
  });
}

export function fetchPlaceById(placeId) {
  return new Promise((resolve, reject) => {
    if (!window.google) {
      return retryLoadGoogleMapsAPI()
        .then(() => fetchPlaceById(placeId))
        .catch(() => reject('fetchPlaceById :: Google Maps API not loaded.'));
    }

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId }, function(results, status) {
      status === OK ? resolve(results[0]) : reject(status);
    });
  });
}
