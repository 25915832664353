// @flow
type IEnvVarName =
  | 'ALGOLIA_APP_ID'
  | 'ALGOLIA_SEARCH_KEY'
  | 'ALGOLIA_SUFFIX'
  | 'API_BASE'
  | 'APP_ENV'
  | 'CLAIM_PORTAL_API_URL'
  | 'CONTENTFUL_ACCESS_TOKEN'
  | 'CONTENTFUL_API_URL'
  | 'CONTENTFUL_ENVIRONMENT'
  | 'CONTENTFUL_SPACE_ID'
  | 'DEPOSIFY_EMBED_URL'
  | 'GOOGLE_API_KEY'
  | 'GOOGLE_STATIC_MAPS_API_KEY'
  | 'INCOME_BUCKETS'
  | 'RECAPTCHA_PUBLIC_KEY'
  | 'RHINO_DESIGN_V2'
  | 'RHINO_URL'
  | 'ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN'
  | 'STRIPE_SUPER_PARENT_CC_ONLY_PAYMENT_METHOD_CONFIG'
  | 'STUB_CLIENT_GOOGLE_MAPS'
  | 'STUB_CLIENT_SEARCH_RESULTS'
  | 'ZENDESK_SCRIPT_URL';

const env = (name: IEnvVarName): string => window.App && window.App.env[name] || '';

export default env;
