import { fetchGooglePredictions, fetchGoogleAddress } from 'utils/google';
import { createIndex } from 'utils/algolia';
import env from 'utils/env';
import type { IAlgoliaResult, IAlgoliaParams } from 'utils/algolia';

import { memoize } from 'lodash';
import { IAlgoliaHit } from 'types';

const propertyIndex = createIndex('Property');
const stubSearchResults = env('STUB_CLIENT_SEARCH_RESULTS') === 'true';

function getSessionToken(): any {
  memoize(() => {
    // @ts-ignore
    return new window.google.maps.places.AutocompleteSessionToken(); // eslint-disable-line
  }); // eslint-disable-line
}

export function fetchUnits(query: string, property_id: number, search_options: any = {}): Promise<any> {
  if (stubSearchResults === true) {
    return new Promise((resolve: (result: any) => any, reject: (error: any) => any) => {
      const unit = { name: '19H', address_line_two: '19H', id: 19 };
      resolve({ units: [unit] });
    });
  }

  const requestUrl = `/properties/${property_id}/units?name=${query}`;

  return new Promise((resolve, reject) => {
    fetch(requestUrl, {
      credentials: 'same-origin',
      headers: { 'Accept': 'application/json' }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        return response.json();
      })
      .then(content => {
        resolve(content);
      })
      .catch(error => {
        reject(error);
      });
    });
}

export function fetchProperties(query: string, options: any = {}): Promise<any> {
  if (stubSearchResults === true) {
    return new Promise((resolve: (result: IAlgoliaHit[]) => any, reject: (error: any) => any) => {
      resolve((window as any)?.fetchPropertiesStub || []);
    });
  }

  return new Promise((resolve: (result: IAlgoliaHit[]) => any, reject: (error: any) => any) => {
    const params: IAlgoliaParams = {
      query,
      restrictSearchableAttributes: ['full_address', 'building_name']
    };
    const { scope, slug } = options;
    const facetFilterName = { development: 'development_slugs' }[scope] || 'owner_slug';

    params.facetFilters = slug && [facetFilterName, slug].join(':');

    propertyIndex.search(params, (err: any, content: IAlgoliaResult) => {
      if (err) {
        reject(err);
      } else {
        const properties: IAlgoliaHit[] = content.hits.map(
          (h: any): IAlgoliaHit => ({
            full_address: [h.address_line_one, h.address_city, h.address_state].join(', '),
            address_line_one: h.address_line_one,
            address_state: h.address_state,
            id: h.google_place_id,
            property_id: h.id,
            owner_requires_invitation: h.owner_requires_invitation,
            leasing_integration_renter_url: h.leasing_integration_renter_url,
            type: 'algolia'
          })
        );
        resolve(properties);
      }
    });
  });
}

export function fetchPredictions(query: string): Promise<any> {
  if (stubSearchResults === true) {
    return new Promise((resolve: (result: IAlgoliaHit[]) => any, reject: (error: any) => any) => {
      resolve((window as any)?.fetchPredictionsStub || []);
    });
  }

  return new Promise((resolve: (result: IAlgoliaHit[]) => any, reject: (error: any) => any) => {
    fetchGooglePredictions(query, getSessionToken())
      .then((response) => {
        const googleHits = response.map((e): IAlgoliaHit => {
          const description = e.description.replace(/, (US|USA)$/, '');
          return {
            full_address: description,
            address_line_one: description,
            address_state: description.split(',').pop().trim(),
            id: e.place_id,
            type: 'google'
          };
        });
        resolve(googleHits);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}
