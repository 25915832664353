import React, { Component } from 'react';
import type { IAlgoliaResult } from 'utils/algolia';

import AutocompleteInput from 'components/common/form/autocomplete-input';

import { fetchUnits } from './api';
import { fieldWithPrefix } from 'utils/form';
import { IAlgoliaHit } from 'types';
import { IUnit } from 'types/algolia/unit';

type IProps = {
  value?: string;
  property: Partial<IAlgoliaHit>;
  wrapperClassName: string;
  disabled: boolean;
  empty?: boolean;
  label?: any;
  onSelect: (unit: IUnit | void) => any;
  onChange: (value: string) => any;
  autocompleteProps?: {
    placeholder?: string;
    inputClassName?: string;
  };
  namePrefix?: string;
  forceRerender?: boolean;
  dataCy?: string;
};

type IState = {
  value?: string;
  unit?: IUnit;
  units: IUnit[];
};

class UnitAutocomplete extends Component<IProps, IState> {
  static defaultProps = {
    wrapperClassName: 'col-md-3',
    disabled: false,
    autocompleteProps: {},
    namePrefix: '',
    forceRerender: false
  };

  constructor(props: IProps) {
    super(props);

    const { value } = props;

    this.state = {
      value: value || '',
      units: []
    };
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.empty === true) {
      this.setState({ value: '', units: [] });
      return;
    }

    if (nextProps.forceRerender === true) {
      this.setState({ value: nextProps.value, units: [] });
    }
  }

  onChange = (value: string) => {
    this.setState({ value });
    this.props.onChange(value);
    
    const propertyID = this.props.property.property_id;
    
    if (propertyID) {
      fetchUnits(value, propertyID).then((result: IAlgoliaResult) => {
        this.setState({ units: result.units });
      });
    }
  };

  onSelect = (unit: IUnit) => {
    this.setState({ unit, value: unit.name });
    this.props.onSelect(unit);
  };

  autocompleteProps = (): any => ({
    fieldName: fieldWithPrefix('address_line_two', this.props.namePrefix),
    placeholder: 'Unit/Floor',
    ariaLabel: 'Unit/Floor',
    value: this.state.value,
    onChange: this.onChange,
    onSelect: this.onSelect,
    matchBy: 'address_line_two',
    options: this.state.units,
    disabled: this.props.disabled,
    fieldLabel: this.props.label,
    dataCy: this.props.dataCy,
    ...this.props.autocompleteProps
  });

  render() {
    const { wrapperClassName, namePrefix } = this.props;
    return (
      <div className={`${fieldWithPrefix('address-unit', namePrefix, '-')} ${wrapperClassName}`}>
        <AutocompleteInput {...this.autocompleteProps()} />
      </div>
    );
  }
}

export default UnitAutocomplete;
