import React, { PureComponent } from 'react';
import Autocomplete from 'react-autocomplete';
import FormGroup from '../form-group';
import Label from '../label';
import { maybeAutoCompleteText } from './maybe-autocomplete';
import Highlighter from 'react-highlight-words';

export type IAutocompleteInputProps = {
  inputName?: string;
  fieldId?: string;
  fieldName: string;
  fieldLabel?: any;
  placeholder: string;
  ariaLabel: string;
  options: any[];
  value: string | number;
  wrapperStyle: {};
  wrapperProps: {};
  inputProps?: any;
  onChange: (value: string) => any;
  onSelect: (item: any) => any;
  autoFocus?: boolean;
  matchBy: string;
  optionTemplate?: (hit: any) => any;
  highlight: boolean;
  disabled?: boolean;
  inputClassName?: string;
  menuStyle?: any;
  dataCy?: string;
};

type IItem = any & { id: any };

const defaultMenuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  fontSize: '90%',
  position: 'absolute',
  top: '40px',
  left: '0',
  width: '100%',
  overflow: 'auto',
  maxHeight: '240px',
  zIndex: 100
};

export default class AutocompleteInput extends PureComponent<IAutocompleteInputProps & any, {}> {
  static defaultProps = {
    wrapperStyle: {},
    wrapperProps: {
      className: 'autocomplete-input-wrapper'
    },
    matchBy: 'name',
    highlight: false
  };

  input;

  componentDidMount() {
    if (this.props.autoFocus === true && this.input) {
      this.input.focus();
    }
    Autocomplete.prototype.maybeAutoCompleteText = maybeAutoCompleteText;
  }

  inputName = (): string => this.props.inputName || this.props.fieldName;
  inputId = (): string => this.props.fieldId || this.props.fieldName;

  onSelect = (value: string, item: any) => {
    this.props.onSelect(item);
  };

  onChange = (_event: {}, value: string) => {
    this.props.onChange(value);
  };

  optionTemplate = (hit: any) => {
    const { value, matchBy } = this.props;

    const highlighProps = {
      textToHighlight: hit[matchBy],
      highlightClassName: 'highlighted-item',
      searchWords: typeof value === 'string' ? value.split(' ') : value
    };
    return <Highlighter {...highlighProps} />;
  };

  renderInput = (props: any) => {
    let autoComplete: any = null;

    if (this.props.inputProps && this.props.inputProps.autoComplete) {
      autoComplete = { autoComplete: this.props.inputProps.autoComplete };
    }
    return <input {...props} {...autoComplete} />;
  };

  renderItem = (item: IItem, isHighlighted: boolean) => {
    const { matchBy, highlight } = this.props;
    const className = `autocomplete-item ${isHighlighted ? 'highlighted' : ''}`;

    return (
      <div key={item.id} id={item.id} className={className}>
        {highlight ? this.optionTemplate(item) : item[matchBy]}
      </div>
    );
  };

  inputProps = (): any => {
    const { disabled, placeholder, inputClassName, inputProps, ariaLabel, dataCy } = this.props;

    let props = {
      id: this.inputId(),
      name: this.inputName(),
      placeholder,
      disabled,
      'aria-label': ariaLabel,
      className: inputClassName,
      'data-cy': dataCy
    };

    if (inputProps !== undefined) {
      props = Object.assign({}, inputProps, props);
    }

    return props;
  };

  render() {
    const { value, options, fieldLabel, wrapperStyle, wrapperProps, matchBy } = this.props;

    return (
      <FormGroup>
        {fieldLabel}
        <Autocomplete
          ref={(el) => (this.input = el)}
          inputProps={this.inputProps()}
          value={value || ''}
          items={options}
          getItemValue={(item) => item[matchBy]}
          selectOnBlur={true}
          onSelect={this.onSelect}
          onChange={this.onChange}
          renderInput={this.renderInput}
          renderItem={this.renderItem}
          wrapperStyle={wrapperStyle}
          wrapperProps={wrapperProps}
          menuStyle={{ ...defaultMenuStyle, ...this.props.menuStyle }}
          className="autocomplete-input"
          data-cy={this.props.dataCy}
        />
      </FormGroup>
    );
  }
}
