import React from 'react';

type IProps = {
  children?: any;
  className?: string;
  hasError?: boolean;
};

const FormGroup = ({ children, className = 'input-holder', hasError = false }: IProps): any => {
  if (hasError) className += ' react-input-error';

  return <div className={className}>{children}</div>;
};

export default FormGroup;
