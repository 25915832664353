// @flow
import React, { Component } from 'react';
import { fetchPlaceById, retryLoadGoogleMapsAPI } from 'utils/google';

type IProps = {
  place_id: string
};

const MAP_ID = 'googleMap';

class Gmap extends Component<IProps> {
  static defaultProps = {
    place_id: ''
  };

  mapSelector = (): HTMLElement | null => document.getElementById(MAP_ID);

  initMap = async (coords: { lat: number, lng: number }): Promise<any> | void => {
    if (!window.google) {
      return retryLoadGoogleMapsAPI()
        .then(() => this.initMap(coords))
        .catch(() => console.warn('initMap :: Google Maps API not loaded.'));
    }

    const map = new window.google.maps.Map(this.mapSelector(), {
      zoom: 15,
      center: coords,
      mapTypeControl: false,
      streetViewControl: false,
      resetBoundsOnResize: true,
      visualRefresh: true
    });

    const marker = new window.google.maps.Marker({
      position: coords,
      map: map
    });

    this.showMap();

    window.google.maps.event.addListener(map, 'idle', function () {
      map.setCenter(marker.getPosition());
      setTimeout(function () {
        window.google.maps.event.trigger(map, 'resize');
      }, 10);
    });
  };

  showMap = () => {
    const elem = this.mapSelector();
    if (elem) {
      elem.className = elem.className.replace(' opened', '').replace('opened', '');
      elem.className += ' opened';
    }
  };

  hideMap = () => {
    const elem = this.mapSelector();
    if (elem) {
      elem.className = elem.className.replace('opened', '');
    }
  };

  shouldComponentUpdate = (nextProps: IProps): boolean => {
    return this.props.place_id !== nextProps.place_id;
  };

  fetchPlace = () => {
    if (!this.props.place_id) return;

    fetchPlaceById(this.props.place_id).then(
      (place) => {
        if (place) {
          const location = place.geometry.location;
          const coords = {
            lat: location.lat(),
            lng: location.lng()
          };

          this.initMap(coords);
        } else this.hideMap();
      },
      (error) => {
        this.hideMap();
      }
    );
  };

  componentDidMount = () => {
    this.fetchPlace();
  };

  componentDidUpdate = () => {
    this.fetchPlace();
  };

  render(): React$Node {
    return <div id={MAP_ID}></div>;
  }
}

export default Gmap;
