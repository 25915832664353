import { IAlgoliaHit } from 'types';
import { MAX_PROPERTY_ITEMS as MAX_ITEMS } from './constants';

/* filtres out duplicate results from google autocomplete with the same
   address line or google place id as properties results from algolia   */
export function filteredPlaces(places: IAlgoliaHit[], properties: IAlgoliaHit[]): IAlgoliaHit[] {
  return places
    .filter((item): boolean => {
      const is_duplicate =
        propertyWithAddress(item.full_address, properties) || propertyWithGooglePlaceId(item.id, properties);

      return !is_duplicate;
    })
    .slice(0, MAX_ITEMS - properties.length);
}

function propertyWithAddress(address: string, properties: IAlgoliaHit[]): void | IAlgoliaHit {
  return properties.find((property) => address.startsWith(property.full_address));
}

function propertyWithGooglePlaceId(id: string, properties: IAlgoliaHit[]): void | IAlgoliaHit {
  return properties.find((property) => id === property.id);
}
