import React, { PureComponent } from 'react';

type IProps = {
  text?: any;
};

const ErrorText = ({ text }: IProps) => (
  <div className="accounts-form--error" dangerouslySetInnerHTML={{ __html: text }} />
);

export default ErrorText;
