import axios from 'axios';
import store from './redux/store';
import { sessionExpired } from './redux/actions';

const AUTH_ERROR_CODE = 401;
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === AUTH_ERROR_CODE) {
    store.dispatch(sessionExpired(true));
  }
  return Promise.reject(error);
});

export const Axios = axios;

export default axiosInstance;
