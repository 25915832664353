import Error from './error';
import Errors from './errors';

export const inputId = (resource?: string, field?: string): string => `${resource}_${field}`;

export const inputName = (resource?: string, field?: string): string => `${resource}[${field}]`;

export class Validator {
  field = '';
  errors = {};

  constructor(field: string, errors: {}) {
    this.field = field;
    this.errors = errors;
  }

  validate(value: string | number | null, rules: string[]): object {
    rules.forEach((rule: string) => {
      // $FlowIgnore: Computed object property not working well
      this.errors = { ...this.errors, ...this[rule](value) };
    });
    return this.errors;
  }

  validateMonthlyRentAmount(value: number): object {
    if (typeof value === 'number' && value > 100) {
      this.errors = new Errors(this.errors).hide(this.field);
    } else {
      this.errors = new Errors(this.errors).add(new Error(this.field, 'must be greater then 100'));
    }
    return this.errors;
  }

  validateDateIsNotEmpty(value: string): object {
    if (value !== null && typeof value !== 'undefined' && value !== 'Invalid date') {
      this.errors = new Errors(this.errors).hide(this.field);
    } else {
      this.errors = new Errors(this.errors).add(new Error(this.field, 'can‘t be blank'));
    }
    return this.errors;
  }
}

// @ts-ignore

type ISignUpForm = {
  id: string;
  action: string;
  className: string;
  csrf_token?: string;
  holderClass?: string;
  'data-remote': string;
};

export const signUpFormProps = (
  url: string,
  csrf_token?: string,
  holderClass?: string,
  user_id?: number | string
): ISignUpForm => {
  return {
    id: `edit_user_${user_id}`,
    action: url,
    className: 'sign-up__form',
    'data-remote': 'false',
    csrf_token,
    holderClass
  };
};

export const fieldWithPrefix = (field: string, prefix: void | string, separator: string = '_'): string => {
  return prefix !== null && prefix !== undefined && prefix !== '' ? `${prefix}${separator}${field}` : field;
};
